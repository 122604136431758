module.exports = [{
      plugin: require('/Users/yokomotod/workspace/yokomotod/yokomotod.dev/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1380,"linkImagesToOriginal":false}},{"resolve":"gatsby-remark-copy-linked-files"},{"resolve":"gatsby-remark-smartypants"}],"remarkPlugins":[null]},
    },{
      plugin: require('/Users/yokomotod/workspace/yokomotod/yokomotod.dev/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/yokomotod/workspace/yokomotod/yokomotod.dev/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/yokomotod/workspace/yokomotod/yokomotod.dev/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N9Z994W"},
    },{
      plugin: require('/Users/yokomotod/workspace/yokomotod/yokomotod.dev/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-51089249-5"},
    },{
      plugin: require('/Users/yokomotod/workspace/yokomotod/yokomotod.dev/node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://4439f9ce5b7a481e8d953e9feba3a07e@sentry.io/1778417","enabled":true},
    },{
      plugin: require('/Users/yokomotod/workspace/yokomotod/yokomotod.dev/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"yokomotod.dev","short_name":"yokomotod.dev","start_url":"/","background_color":"#007acc","theme_color":"#007acc","display":"minimal-ui","icon":"src/images/icon.png"},
    },{
      plugin: require('/Users/yokomotod/workspace/yokomotod/yokomotod.dev/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
